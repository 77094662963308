import { DatePicker, InputWrapper, UnimportantVerticalCenter } from '@/components/common'
import InputLabel from '@/components/common/InputLabel'
import { css } from 'styled-system/css';
import { isArray } from 'lodash'
import React from 'react'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import { dotPathWalk } from '../../utils'
import { Flex } from '@planda/design-system'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { addMonths, isSameDay } from 'date-fns'
import { schoolDefaults } from 'src/types'
import { useAppSelector } from '@/redux/hooks';

const StartEndDates = ({ control, errors, register, name = 'date' }:
    {
        control: Control<any>, errors?: any, register: UseFormRegister<any>,
        name?: string
    }) => {
    const isStudent = useAppSelector(state => state.featureAccess.plugins.student)
    const error = dotPathWalk(errors, name)
    return (
        <Controller
            name={`${name}.0`}
            control={control}
            render={({ field: startField }) => (
                <Controller
                    name={`${name}.1`}
                    control={control}
                    render={({ field: endField }) => <Flex direction={'column'} gap={'3'}>
                        <InputWrapper>
                            <InputLabel >&#128994;&nbsp;Starting from:</InputLabel>

                            <DatePicker quickMode format={'short'} {...startField} error={isArray(error) && error[0]?.message} />
                            <InputLabel >&#128308;&nbsp;Ends:</InputLabel>
                            <DatePicker quickMode placeholder='indefinitely' format={'short'} {...endField} error={isArray(error) && error[1]?.message}
                                customQuickAddDates={[
                                    {
                                        label: 'end of term',
                                        date: (_: Date | null | undefined) => schoolDefaults['University of British Columbia'].terms[0].teachingEnds,
                                        unit: 'date',
                                        hide: !isStudent
                                    },
                                    {
                                        label: '3 mo',
                                        date: (_: Date | null | undefined) => addMonths(new Date(), 3),
                                        unit: 'date',
                                    },
                                    {
                                        label: 'add 1 mo',
                                        date: (date: Date | null | undefined) => addMonths(date || new Date(), 1),
                                        unit: 'date',
                                    },
                                ]}
                            />
                        </InputWrapper>
                        {isSameDay(startField.value, endField.value) && <UnimportantVerticalCenter className={css({ color: '$error9' })}><ExclamationTriangleIcon />Error above: Start and Ends are same day</UnimportantVerticalCenter>}
                    </Flex>}
                />
            )}
        />
    )
}

export default StartEndDates