import { InputWrapper } from '@/components/common'
import InputLabel from '@/components/common/InputLabel'
import { css } from 'styled-system/css';
import Select from '@/components/common/select'
import React from 'react'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import FormError from './FormError'
import { NumberInput } from '@planda/design-system'
import { dotPathWalk } from '../../utils'

const Duration = ({ control, errors, path }:
    { control: Control<any>, errors?: any, register: UseFormRegister<any>, path?: string }) => {
    const pathToCur = path ? path + '.' : ''
    const curErrs = path ? dotPathWalk(errors, path) : errors
    return (
        <InputWrapper>
            <InputLabel >&#8987; &#65039;&nbsp;Duration:</InputLabel>
            <Controller
                name={pathToCur + "durationValue"}
                control={control}
                render={({ field }) => (
                    // why did I do debounceWait={0} because only rely on focus change
                    <NumberInput className={css({ width: 'min-content', display: 'inline' })} error={!!curErrs?.durationValue?.message} debounceWait={0} precision={1} min={0} max={100} {...field} onChange={field.onChange} />
                )} />
            {/* <Input
                {...register('durationValue', { valueAsNumber: true })} // durationUnit
                className={css({ width: 'min-content', display: 'inline' })}
                type="number"
                min={0}
                max={99}
                step={0.1}
                id="counter"
                size={'small'}
                error={!!errors?.durationValue?.message}
            /> */}
            <FormError name={pathToCur + "durationValue"} errors={errors} />
            <Controller
                name={pathToCur + "durationUnit"}
                control={control}
                render={({ field: { ref, ...field } }) => {
                    return <Select
                        {...field}
                        data={[{
                            type: 'item',
                            label: 'min',
                            value: 'minute',
                        },
                        {
                            type: 'item',
                            label: 'hr',
                            value: 'hour',
                        },
                        {
                            type: 'item',
                            label: 'days',
                            value: 'day',
                        }]}
                        error={!!curErrs?.durationUnit?.message}
                    />
                }}
            />
        </InputWrapper>
    )
}

export default Duration