import { forwardRef, ReactNode } from 'react'
import * as SelectPrimitive from '@radix-ui/react-select';
import { CheckIcon, PlusIcon } from '@radix-ui/react-icons';
import { styled } from 'styled-system/jsx'

interface Props {
    children: ReactNode,
    value: string,
}

const Item = forwardRef<HTMLInputElement, Props>(({ children, ...props }, forwardedRef) => {
    return (
        <SelectItem {...props} ref={forwardedRef}>
            <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
            <Indicator>
                <CheckIcon />
            </Indicator>
        </SelectItem>
    )
});
Item.displayName = 'Item'

export default Item

const itemStyle = {
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
    color: '$primary11',

    padding: '0 35px 0 25px',
    position: 'relative',
    userSelect: 'none',
    borderRadius: 3,

    height: 25,
    fontSize: 13,
    lineHeight: 1,

    '&[data-disabled]': {
        color: '$gray8',
        pointerEvents: 'none',
    },

    '&[data-highlighted]': {
        backgroundColor: '$primary9',
        color: '$primary1',
    },
} as const

const SelectItem = styled(SelectPrimitive.Item, { base: {
    ...itemStyle,
} });

export const ItemButton = (({ children, handleClick }: { children: ReactNode, handleClick: ()=>void }) => {
    return (
        <SelectItemButton onClick={handleClick}>
            <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
            <Indicator>
                <CheckIcon />
            </Indicator>
        </SelectItemButton>
    )
});

export const SelectItemButtonIndicator = styled('div', {
    base: {
        position: "absolute",
        left: 0,
        width: 25,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center"
    }
});

const SelectItemButton = styled('button', { base: {
    ...itemStyle,
    width: '100%',
} });

const Indicator = styled(SelectPrimitive.ItemIndicator, {
    base: {
        position: "absolute",
        left: 0,
        width: 25,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center"
    }
});