import React from 'react';
import { Control, Controller, ControllerRenderProps } from "react-hook-form";
import { css } from 'styled-system/css';
import { addMinutes } from 'date-fns';
import { TimePicker } from 'src/components/common/DateTimeInputs';
import { FieldErrorsImpl } from 'react-hook-form';
import { isArray } from 'lodash';
import { dotPathWalk } from '../../utils';

const TimeRangeInput = ({ errors, control, name }: {
    errors: Partial<FieldErrorsImpl<any>>; control: Control<any>,
    name: string
}) => {
    return (
        <Controller
            name={`${name}.0`}
            control={control}
            render={({ field: fieldOne }) => {
                const curErrors = dotPathWalk(errors, name)
                return <>
                    <TimePicker quickMode maw={90}
                        error={isArray(curErrors) && curErrors[0]?.message} {...fieldOne} />
                    <span className={css({ marginBottom: 10 })}>–</span>
                    <Controller
                        name={`${name}.1`}
                        control={control}
                        render={({ field }) => <TimeEndPicker errorMessage={isArray(curErrors) && curErrors[1]?.message} field={field} timeStartField={fieldOne} />}
                    />
                </>
            }}
        />
    )
}

export default TimeRangeInput

export function TimeEndPicker({ errorMessage, field, timeStartField }: { errorMessage: string | undefined; field: ControllerRenderProps<any, any>; timeStartField: ControllerRenderProps<any, any>; }) {
    return <TimePicker maw={90} error={errorMessage} {...field}
        quickMode
        quickAddOptions={[
            {
                label: '30min',
                date: (date: Date | null | undefined) => addMinutes(timeStartField.value || new Date().setHours(0, 0, 0, 0), 30),
                unit: 'time',
            },
            {
                label: '1hr',
                date: (date: Date | null | undefined) => addMinutes(timeStartField.value || new Date().setHours(0, 0, 0, 0), 60),
                unit: 'time',
            },
        ]} />;
}
