import { DatePicker, Input, InputWrapper, TimePicker, TriangleNav } from '@/components/common'
import InputLabel from '@/components/common/InputLabel'
import { css } from 'styled-system/css';
import { isArray } from 'lodash'
import React from 'react'
import { Control, Controller, useController, UseFormRegister } from 'react-hook-form'
import { styled } from 'styled-system/jsx'
import FormError from './FormError'
import { Button } from '@planda/design-system'
import DayOfTheWeek from './DayOfTheWeek'
import { dotPathWalk } from '../../utils'

const Cron = ({ control, errors, register, path, label = 'Due', includeDatePicker = false, disableDatePopover }: {
    control: Control<any>, errors?: any, register: UseFormRegister<any>, label?: string,
    includeDatePicker?: boolean, path?: string, disableDatePopover?: boolean
}) => {
    const pathToCur = path ? path + '.' : ''
    const curErrors = path ? dotPathWalk(errors, path) : errors

    const { field: formatField } = useController({
        name: pathToCur + 'cronInfo.formatCron',
        control,
    });
    const { value: formatCron, onChange } = formatField
    const toggleFormat = () => {
        onChange(formatCron ? 0 : 1)
    }

    return (
        <InputWrapper>
            <InputLabel >&#128337;&nbsp;&nbsp;{label}</InputLabel>
            <Flex>
                <TriangleNav direction='left' height={0.8} fn={toggleFormat} />
                {formatCron ?
                    <DayOfTheWeek control={control} errors={errors} name={pathToCur + 'cronInfo.daysOfWeek'} /> :
                    <FlexV>
                        <Flex>
                            every <Input
                                {...register(pathToCur + 'cronInfo.rateIntervalDays', { valueAsNumber: true })}
                                className={css({ width: 'min-content', display: 'inline' })}
                                type="number"
                                min={0}
                                max={99}
                                id="counter"
                                size={'small'}
                                error={!!curErrors?.rateIntervalDays?.message}
                            /> days{includeDatePicker ? ' from' : ''}</Flex>
                        <FormError name={pathToCur + 'cronInfo.rateIntervalDays'} errors={errors} />
                        {includeDatePicker && <Controller
                            name={pathToCur + "date.0"}
                            control={control}
                            render={({ field }) => <DatePicker disablePopover={disableDatePopover} {...field} error={isArray(curErrors?.date) && curErrors.date[0]?.message} />}
                        />}
                    </FlexV>
                }

                <TriangleNav direction='right' height={0.8} fn={toggleFormat} />
            </Flex>
            <Controller
                name={pathToCur + "time.0"}
                control={control}
                render={({ field }) => <TimePicker error={isArray(curErrors?.time) && curErrors.time[0]?.message && 'Time required'} {...field} />}
            />
            <Button onClick={toggleFormat} className={css({ opacity: 0.5, fontSize: '0.5em' })} variant={'gray'} >Switch to {formatCron ? 'interval' : 'day of week'}</Button>
        </InputWrapper>
    )
}

export default Cron

const FlexV = styled('div', {
    base: {
        display: "flex",
        gap: 10,
        flexDirection: "column",
        alignItems: "center"
    }
})
const Flex = styled('div', {
    base: {
        display: "flex",
        gap: 12,
        alignItems: "center"
    }
})