import z from 'src/lib/zod'
import { MS_PER_30_DAYS, MS_PER_DAY } from 'src/constants/date'
import { PutTemplateDBMergeSchema, PutTaskSchema, PutEventSchema, PutTemplateRecurSchema, Merge } from 'src/types'
import { isBefore, isEqual } from 'date-fns'
import { changeTime } from '@planda/utils'
import { getCronParser, nextAdd } from './utils'
import { millisecondsInHour, millisecondsInMinute } from '@planda/utils'
import { Control, FieldErrorsImpl, FieldValues, UseFormRegister, UseFormWatch } from 'react-hook-form'

export type Error = Partial<FieldErrorsImpl<FieldValues>>

export type FormInputProps = {
    control: Control<any>
    register: UseFormRegister<any>
    errors: Partial<FieldErrorsImpl<any>>
    watch: UseFormWatch<any>
}

export const MAX_ITEM_DURATION = MS_PER_30_DAYS

export const FormLinkSchema = z.object({
    text: z.string().optional(),
    url: z.string().url().or(z.string().length(0)),
})

const FormTaskSchema = PutTaskSchema.omit({ dateStart: true, type: true }).extend({
    // type: z.literal('task'),
    formPath: z.literal('task'), // lvl2 is controlled by useState for task?
    date: z.tuple([z.date().nullable(), z.date().nullable()]), // use date[0] only
    time: z.tuple([z.date().nullable(), z.date().nullable()]),
    handedOutDate: z.date().nullable().optional(),
})

const FormEventSchema = PutEventSchema.omit({ dateStart: true, dateEnd: true, type: true }).extend({
    // type: z.literal('event'),
    formPath: z.literal('event.event'),
    date: z.tuple([z.date(), z.date().nullable()]), // if isMultiDay=false, use first value of dateRange
    isMultiDay: z.boolean(),
    time: z.array(z.date()).length(2),
})
// .refine((val) => {
//     const { date, isMultiDay } = val
//     if (isMultiDay && !date[1]) {
//         return false
//     }
// })

const cronRateSchema = z.discriminatedUnion('formatCron', [
    z.object({
        formatCron: z.literal(0),
        rateIntervalDays: z.number().positive(),
    }),
    z.object({
        daysOfWeek: z.array(z.coerce.number().gte(0).lt(7)).min(1, { message: 'Select at least one' }).optional(),
        formatCron: z.literal(1),
    }),
])
export type FormCronRate = z.infer<typeof cronRateSchema>
const UNIT_TO_MS = {
    minute: millisecondsInMinute,
    hour: millisecondsInHour,
    day: MS_PER_DAY,
}
// how to apply union for rate vs cron, use nesting? {cron: {...stuff  here}}
const FormTemplateRecurEventSchema = PutTemplateRecurSchema.omit({ dateStart: true, dateEnd: true, cron: true, type: true, childType: true }).extend({
    // type: z.literal('templateRecur'),
    // childType: z.literal('event'),
    formPath: z.literal('event.templateRecur'),
    date: z.tuple([z.date(), z.date().nullable()]), // starting from needed for cron too
    durationUnit: z.union([z.literal('minute'), z.literal('hour'), z.literal('day')]),
    durationValue: z.number().positive(),
    cronInfo: cronRateSchema,
    time: z.tuple([z.date(), z.date().nullable()]),
    // level1: z.literal('event'),
    // level2: z.literal('templateRecur'),
})
export type FormTask = z.infer<typeof FormTaskSchema>
export type FormEvent = z.infer<typeof FormEventSchema>
export type FormTemplateRecurEvent = z.infer<typeof FormTemplateRecurEventSchema>

export function isFormEvent(item: unknown): item is FormEvent {
    return (item as FormEvent).formPath === 'event.event'
}

export function isFormTask(item: unknown): item is FormTask {
    return (item as FormTask).formPath === 'task'
}

// export function isFormTemplateDB(item: unknown): item is FormTemplateDBItem {
//     return (<TemplateDB>item).type === 'templateDB';
// }

export function isFormTemplateRecurEvent(item: unknown): item is FormTemplateRecurEvent {
    return (item as FormTemplateRecurEvent).formPath === 'event.templateRecur'
}

// TODO: use union instead, cuz too complicated
// need a schema for tasks, for events, for templateRecur, then or them
export const FormItemSchema = z.discriminatedUnion('formPath', [FormTaskSchema, FormEventSchema, FormTemplateRecurEventSchema]).transform((val, ctx) => {
    if (isFormEvent(val)) {
        const { isMultiDay, date, time } = val
        if (isMultiDay && !date[1]) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Invalid date range',
                path: ['date'],
            })
            return z.NEVER
        }
        const start = changeTime(date[0], time[0])
        const end = changeTime((isMultiDay ? date[1] : date[0]) as Date, time[1])
        if (!z.date().min(start).safeParse(end).success) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'start time must be before end time',
                path: ['time'],
            })
            return z.NEVER
        }
        return {
            ...val,
            dateStart: date[0] && changeTime(date[0], time[0]).getTime(),
            dateEnd: changeTime((isMultiDay ? date[1] : date[0]) as Date, time[1]).getTime(),
            type: 'event',
        }
    } else if (isFormTask(val)) {
        const { date, time, handedOutDate } = val
        // if (!date[0] !== !time[0]) {
        //     console.error("diff error")
        //     ctx.addIssue({
        //         code: z.ZodIssueCode.custom,
        //         message: 'Invalid ' + (date[0] ? 'time' : 'date'),
        //         path: date[0] ? ['time.0'] : ['date.0']
        //     });
        //     return z.NEVER
        // } else {
        return {
            ...val,
            ...(date[0] && { dateStart: date[0].getTime() }),
            dateHandedOut: handedOutDate?.setHours(8, 0, 0, 0) ?? undefined,
            type: 'task',
        }
        // }
    } else if (isFormTemplateRecurEvent(val)) {
        const { date, time, durationUnit, durationValue } = val
        const duration = UNIT_TO_MS[durationUnit] * durationValue
        if (date[1] && (isBefore(date[1], date[0]) || isEqual(date[1], date[0]))) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'end must be after start',
                path: ['date.1'],
            })
            return z.NEVER
        }
        const { formatCron, rateIntervalDays, daysOfWeek } = val.cronInfo as Merge<FormCronRate>

        const parser = getCronParser(formatCron, time[0], daysOfWeek, val.date[0] as Date, rateIntervalDays, 0, duration)
        if (!parser) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Invalid cron, should never be reached',
                fatal: true,
            })
            return z.NEVER
        }

        return {
            ...val,
            cron: parser.getCron(),
            dateStart: changeTime(date[0] as Date, time[0]).getTime(),
            dateEnd: date[1] ? changeTime(date[1] as Date, time[0]).getTime() : undefined,
            type: 'templateRecur',
            childType: 'event',
        }
    }
    return z.NEVER
})

// TodoItemMergeSchema.partial({ id: true, createdAt: true, updatedAt: true, dateStart: true, dateEnd: true }).required({ completed: true, type: true }).extend({
//     date: z.date().nullable(),
//     time: z.date().nullable(),
//     dateRange: z.array(z.date()).length(2).or(z.array(z.null()).length(2)),
//     timeRange: z.array(z.date()).length(2).or(z.array(z.null()).length(2)),
//     links: z.preprocess((val: any) => preFilterArrayByField(val, 'url'), z.array(LinkSchema).optional()),
//     subtasks: z.preprocess((val: any) => preFilterArrayByField(val, 'name'), z.array(SubtaskSchema).optional()),
//     type: todoItemTypeSchema.or(z.literal('templateRecur')),

//     // TODO: cron for templateRecur
//     durationUnit: z.union([z.literal('minute'), z.literal('hour'), z.literal('day')]),
//     durationValue: z.number().nullable().or(z.nan()).optional(),
//     formatCron: z.literal(0).or(z.literal(1)),
//     daysOfWeek: z.array(z.coerce.number().gte(0).lt(7)).optional(), // .min(1, { message: "Select at least one" }).optional(),
//     rateIntervalDays: z.number().nullable().or(z.nan()).optional(),
// })

// export const FormConvertedItemSchema = FormItemSchema.transform((val, ctx) => {
//     const { date, time, dateRange, timeRange, type, ...rest } = val
//     console.log('in convert')
//     console.log(val)
//     if (type === 'event') {
//         if (!timeRange[0] || !timeRange[1]) {
//             ctx.addIssue({
//                 code: z.ZodIssueCode.custom,
//                 message: 'Required',
//                 path: ['timeRange']
//             });
//         }
//         if (!date === !dateRange[0]) { // both date should never happen
//             if (!date) {
//                 ctx.addIssue({
//                     code: z.ZodIssueCode.custom,
//                     message: 'Required',
//                     path: ['date', 'dateRange']
//                 });
//             } else {
//                 throw new Error('Both date and dateRange in form')
//             }
//         }
//     } else {
//         if (!date !== !time) {
//             ctx.addIssue({
//                 code: z.ZodIssueCode.custom,
//                 message: 'Required or clear',
//                 path: [date ? 'time' : 'date']
//             });
//         }
//     }

//     // exceptions
//     // events wi
//     const dateStart = date && time ? changeTime(date, time).getTime() : date && timeRange[0] ? changeTime(date, timeRange[0]).getTime() : dateRange[0] && timeRange[0] ? changeTime(dateRange[0], timeRange[0]).getTime() : undefined;
//     const dateEnd = type === 'task' ? undefined : date && timeRange[1] ? changeTime(date, timeRange[1]).getTime() : dateRange[1] && timeRange[1] ? changeTime(dateRange[1], timeRange[1]).getTime() : undefined
//     if (dateStart && dateEnd && !isBefore(dateStart, dateEnd)) {
//         ctx.addIssue({
//             code: z.ZodIssueCode.custom,
//             message: 'End date must be after start',
//             path: ['timeRange'] // can't be dateRange cuz of input
//         });
//     }
//     if (dateStart && dateEnd && Math.abs(differenceInMilliseconds(dateEnd, dateStart)) > MAX_ITEM_DURATION) {
//         ctx.addIssue({
//             code: z.ZodIssueCode.custom,
//             message: 'Duration too long',
//             path: ['dateRange']
//         });
//     }
//     return {
//         ...rest,
//         ...(dateStart && { dateStart }),
//         ...(dateEnd && { dateEnd }),
//         type,
//     }
// })
export type FormItem = z.infer<typeof FormItemSchema>
// export type FormConvertedItem = z.infer<typeof FormConvertedItemSchema>

// how to handle cron? handle at end
// rate + cron
// @ts-ignore TODO: type too complex
export const FormTemplateDBSchema = PutTemplateDBMergeSchema.omit({ cronStart: true })
    .extend({
        cronInfo: cronRateSchema,
        // date: z.date(),
        date: z.tuple([z.date().nullable(), z.date().nullable()]), // TODO: allow invalid input for date with formatCron === 0
        time: z.tuple([z.date(), z.date().nullable()]),
        cronNextAddOffset: z.number().nonnegative(),
        daysAddInAdvance: z.number().nonnegative(),
    })
    .transform((val, ctx) => {
        const { time, date, cronInfo, daysAddInAdvance, cronNextAddOffset, ...rest } = val
        const { formatCron, rateIntervalDays, daysOfWeek } = cronInfo as Merge<FormCronRate>

        if (!formatCron && !date[0]) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Date required',
                path: ['date'],
            })
        }
        const parser = getCronParser(formatCron, time[0], daysOfWeek, date[0] || new Date(), rateIntervalDays, daysAddInAdvance)
        if (!parser) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Invalid cron but this error should never get thrown',
                fatal: true,
            })
            return z.NEVER
        }
        const nextAddDate = nextAdd(parser, cronNextAddOffset, daysAddInAdvance)
        if (!nextAddDate) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Invalid next add date but this error should never get thrown',
                fatal: true,
            })
            return z.NEVER
        }

        // console.log("cronStart", date[0] && changeTime(date[0], time[0]).getTime())
        return {
            ...rest,
            cron: parser.getCron(),
            nextAdd: nextAddDate.getTime(),
            ...(!formatCron && date && { cronStart: date[0] && changeTime(date[0], time[0]).getTime() }),
            daysAddInAdvance,
            type: 'templateDB',
            childType: 'task',
        }
    })

// .transform((val, ctx) => {
//     // FOR TASKS ONLY
//     const { date, time } = val
//     const { formatCron, rateIntervalDays, daysOfWeek } = val.cronInfo as Merge<FormCronRate>

//     const cronStartDate = changeTime(date[0] || new Date(), time[0])

//     const parser = getCronParser(formatCron, time[0], daysOfWeek, cronStartDate, rateIntervalDays, 0)
//     if (!parser) {
//         ctx.addIssue({
//             code: z.ZodIssueCode.custom,
//             message: 'Invalid cron. TODO: should be allowed if formatCron is 0',
//             fatal: true,
//         })
//         return z.NEVER
//     }

//     return {
//         ...val,
//         cronStart: cronStartDate.getTime(),
//         cron: parser.getCron(),
//         type: 'templateDB',
//         childType: 'task',
//     }
// })
export type FormTemplateDBItem = z.infer<typeof FormTemplateDBSchema>
