import React from 'react'
import { Control, FieldErrorsImpl, UseFormRegister, UseFormWatch } from 'react-hook-form'
import AutoInputArray from '../../components/AutoInputArray'
import { EMPTY_LINK, MIN_LINKS } from '../../constants'

const Links = ({ control, register, errors, watch, hideLabel }: {
    control: Control<any>,
    register: UseFormRegister<any>,
    watch: UseFormWatch<any>,
    errors: Partial<FieldErrorsImpl<any>>,
    hideLabel?: boolean
}) => {
    return (
        <AutoInputArray hideLabel={hideLabel} name={'links'} register={register} control={control} watch={watch} title='Links'
            empty={EMPTY_LINK} minLength={MIN_LINKS}
            removeCond={(x) => !x?.text && !x?.url} appendCond={(x) => x?.url}
            properties={['text', 'url']}
            errors={errors}
        />
    )
}

export default Links