import { styled } from 'styled-system/jsx'
import InputLabel from '@/components/common/InputLabel';
import { css } from 'styled-system/css';
import { Control, FieldErrorsImpl, FieldValues, useFieldArray, UseFormRegister, UseFormWatch, useWatch } from 'react-hook-form';
import { BulletInputsLineWrapper, FloatableInput } from '@/components/common';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorText } from '../styles';
import { isArray, startCase } from 'lodash';

type Error = Partial<FieldErrorsImpl<FieldValues>>
export interface Input {
    field: string,
    type: string,
    placeholder: string,
}

export interface FieldArrayProps {
    name: string, property?: string,
    errors: Error,
    properties?: string[],
    title: string,
    register: UseFormRegister<any>,
    style?: any,
    labelAlign?: 'center' | 'left' | 'leftcenter',
    showWhenEmpty?: boolean,
}

const FieldArray = ({ name, properties, title, showWhenEmpty = false, style = {}, fields, remove, hideLabel = false, labelAlign = 'leftcenter', ...props }: {
    remove: any, fields: any, hideLabel?: boolean
} & FieldArrayProps) => {

    const labelMarginLeft = labelAlign === 'leftcenter' ? '-50%' : labelAlign === 'center' ? 0 : '-75%'

    if (!showWhenEmpty && (!fields || fields.length <= 0)) return <></>
    return (
        <Area style={style} >
            {!hideLabel && <InputLabel className={css({ marginBottom: -5, marginTop: 5 })} style={{ marginLeft: labelMarginLeft }} >{title}</InputLabel>}

            {fields.map((item: any, index: number) => {
                return (
                    <BulletInputsLineWrapper key={item.id + name + index} handleDelete={() => remove(index)}>
                        {
                            properties?.map(property => {
                                const lineName = (name + `.${index}` + (property ? `.${property}` : ``))
                                return <TextInput key={lineName} {...props} lineName={lineName} property={property} />
                            })
                        }
                    </BulletInputsLineWrapper>)
            })}
        </Area>
    )
}

export default FieldArray


const TextInput = ({ property, errors, lineName, register }: { property: string, errors: Error, lineName: string, register: UseFormRegister<any> }) => {
    return <FloatableInput key={'inputline-' + property} placeholder={startCase(property)}  {...register(lineName)}>
        <ErrorMessage
            errors={errors}
            name={lineName}
            render={({ message }) => <ErrorText>{message}</ErrorText>}
        />
    </FloatableInput>
}

const Area = styled('div', {
    base: {
        position: "relative",
        gridArea: "subtasks",
        flexGeneral: "column",
        gap: 12,
        fontSize: "0.95em",
        flexWrap: "wrap"
    }
})