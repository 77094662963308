import { ToggleGroup } from '@/components/common'
import React from 'react'
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form'
import FormError from './FormError'
import { DAYS_OF_WEEK } from '@constants/date'

const DOW_GROUP_ITEMS = DAYS_OF_WEEK.map((day: string, i: number) => ({ value: i.toString(), label: day[0] }))

const DayOfTheWeek = ({ control, errors, name = 'daysOfWeek' }: { control: Control<any>, errors: Partial<FieldErrorsImpl<any>>, name?: string }) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => <><ToggleGroup
                {...field}
                onValueChange={field.onChange}
                type={'multiple'}
                items={DOW_GROUP_ITEMS}
                error={<FormError errors={errors} name={name} />}
            /></>
            }
        />
    )
}

export default DayOfTheWeek