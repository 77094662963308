import { ContextMenuUnit } from "@/components/common";
import useCategories from '@/hooks/useCategories';
import { useCallback, useMemo } from "react";
import { CategoryRes, Item, ItemType, getEmptyCategoryRes } from "src/types";
import { getChildTypeFromId, getTypeFromId } from "@/utils/item";
import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import { useGlobalUserActionLog } from "src/context/useUserActionLog";
import { useDeletePlannerItemMutation, useGetCategoryResQuery, useUpdatePlannerItemMutation } from "@/redux/features/api/apiSlice";
import { useParams, usePathname } from "next/navigation";
import Swal from 'sweetalert2'
import { getDayStringFormat } from "@planda/utils";
export type EditItem = (id: string, updates: Record<string, any>) => Promise<unknown>
// how to i allow this to open a form? pass in setOpen
export default function useActive(type: ItemType, path?: string) {
    const swrRes = useGetCategoryResQuery()
    const [updateItem] = useUpdatePlannerItemMutation({ fixedCacheKey: 'planner-list' })
    const [deleteItem] = useDeletePlannerItemMutation({ fixedCacheKey: 'planner-list' })

    const { addAction, } = useGlobalUserActionLog()

    const pathname = usePathname()
    const { category } = (useParams() || {}) as { category?: string[] }

    path = path ?? (category?.join('/') || '')
    const categoryProps = useCategories(path)

    const items: Item[] = useMemo(() => {
        if (!swrRes.data) {
            return []
        }
        if (!path) {
            // TODO: why does this throw an error?
            return swrRes.data.items[type]
        }
        return (swrRes.data.items[type] as Item[]).filter((x: Item) => x.category.startsWith(path as string))
    }, [swrRes.data, path, type])

    function find(id: string) {
        return items?.find(x => x.id === id)
    }

    function editItem(item: any, updates: Record<string, any>) {
        if (!item) return
        Object.entries(updates).forEach(([key, val]) => {
            item[key] = val
        })
    }

    function shallowEdit(x: CategoryRes | undefined, id: string, updates: Record<string, any>) {
        const type = getTypeFromId(id)
        const item = find(id)
        if (!x) return getEmptyCategoryRes();
        if (!item) return x;

        const itemRef = (x.items[type] as Item[]).find(x => x.id === id)
        editItem(itemRef, updates)

        return { ...x }
    }

    // handles set only
    const edit = useCallback(async function (id: string, updates: Record<string, any>, promise?: Promise<any>) {
        return updateItem({ id, updates: { set: updates } }) // remove automatically handled in updateItem
    }, [updateItem])


    async function fieldAppend(id: string, propertyName: string, listItems: any[]) {
        return updateItem({ id, updates: { listAppend: { [propertyName]: listItems } } })
    }

    const exclude = useCallback(async (id: string, date: number) => {
        if (getTypeFromId(id) !== 'templateRecur' || getChildTypeFromId(id) !== 'event') return
        const val = getDayStringFormat(date)
        return updateItem({ id, updates: { add: { excludedDays: [val] } } })
    }, [updateItem])

    async function remove(id: string) {
        const item = find(id)
        if (!item) return

        if (item.recurId) {
            console.log("removing", id, item)
            const res = await Swal.fire({
                title: `Deleting ${item.name}`,
                text: `Delete all past and future events for ${item.name}?`,
                icon: "question",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Yes, delete all",
                denyButtonText: "Just this one",
            });
            if (res.isDismissed) return
            if (res.isConfirmed) {
                id = item.recurId
            } else if (res.isDenied && 'dateStart' in item && item.dateStart) {
                return exclude(item.recurId, item.dateStart)
            }
        }

        item && addAction({
            actionType: 'item.delete',
            timestamp: Date.now(),
            page: pathname ?? undefined,
            location: 'item',
            body: item
        })

        return deleteItem(id)
    }

    /**
     *
     * @param item
     * @param handleEdit a function that opens a form, or switches tabs in a form
     * @returns
     */
    const createItemContextMenu = (item: Item, handleEdit: () => void): ContextMenuUnit[] => {
        if (!item.id) return [] // loading or unfinished add item

        return [
            {
                label: 'Edit',
                rightSlot: <Pencil1Icon />,
                onSelect: handleEdit, // TODO should open up a form, or switch tabs if inForm=true
            },
            {
                label: 'Delete',
                rightSlot: <TrashIcon />,
                onSelect: () => remove(item.id),
            },
        ]
    }

    return {
        ...categoryProps,
        ...swrRes,
        categoryRes: swrRes.data,
        data: swrRes.data?.items[type],
        edit, remove, items, categories: swrRes.data?.categoryUnits,
        parentIdDict: swrRes.data?.parentIdDict,
        shallowEdit, append: fieldAppend, createItemContextMenu
    }
}
