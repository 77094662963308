import React from 'react'
import { styled } from 'styled-system/jsx'
import { SelectTrigger, Value, SelectIcon } from '@radix-ui/react-select';
import { ChevronDownIcon } from '@radix-ui/react-icons';

const Trigger = ({placeholder = 'Select a category', error = false}:{placeholder?: string, error?: boolean}) => {
    return (
        <StyledTrigger error={error} aria-label="select-trigger">
            <Value placeholder={placeholder} />
            <SelectIcon>
                <ChevronDownIcon />
            </SelectIcon>
        </StyledTrigger>
    )
}

export default Trigger

const StyledTrigger = styled(SelectTrigger, {
    variants: {
        color: {
            light: {},
            mid: {
                backgroundColor: "$primary2"
            }
        },
        error: {
            true: {
                color: "$error11",
                boxShadow: "$error"
            }
        }
    },
    base: {
        all: "unset",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 4,
        padding: "0 15px",
        fontSize: 13,
        lineHeight: 1,
        height: 35,
        gap: 5,
        backgroundColor: "$primary1",
        color: "$primary11",
        boxShadow: "inset 0px 0px 3px colors.$primary7",
        "&:hover": {
            backgroundColor: "$gray3"
        },
        "&:focus": {
            boxShadow: "$focus"
        },
        "&[data-placeholder]": {
            color: "$primary9"
        },
        gridArea: "category"
    }
});