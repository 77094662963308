import React from 'react';
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { DatePicker, DateSeparateRangeInput } from 'src/components/common/DateTimeInputs';
import { FieldErrorsImpl } from 'react-hook-form';
import { dotPathWalk } from '../../utils';

const DateRangeInput = ({ errors, watch, control, name, isMultiDay = false, quickMode = true, disablePopover = false }: {
    errors: Partial<FieldErrorsImpl<any>>; control: Control<any>,
    name: string,
    watch: UseFormWatch<any>,
    isMultiDay?: boolean,
    quickMode?: boolean,
    disablePopover?: boolean
}) => {
    const date = watch(name)
    return <Controller
        name={`${name}.0`}
        control={control}
        render={({ field }) => {
            const curErrors = dotPathWalk(errors, name)
            return <Controller
                name={`${name}.1`} //"date.1"
                control={control}
                render={({ field: end }) => {
                    if (!isMultiDay) return <DatePicker disablePopover={disablePopover} quickMode={quickMode} {...field} value={date[0]} error={curErrors?.message} />
                    return <DateSeparateRangeInput start={field} end={end} />
                }}
            />
        }}
    />
}
export default DateRangeInput