import { MS_PER_DAY, MS_PER_HOUR } from "@constants/date"
import { TemplateDBMerge, TodoItemMerge } from "src/types/item"
import { changeTime } from "@planda/utils"
import { isSameDay } from "date-fns"
import { secondsInDay } from "date-fns/constants"
import EventCronParser from "event-cron-parser"
import { ParsedRate } from "event-cron-parser/built/lib/parse"
import { cloneDeep, isArray, round } from "lodash"
import { DEFAULT_CRON, DEFAULT_RATE, EMPTY_LINK, EMTPY_SUBTASK, FORM_ITEM_DEFAULTS } from "./constants"
import { ParentIdDict } from "src/types/api"

export const dotPathWalk = (obj: any, path: string) => {
    const parts = path.split('.')
    let cur = obj
    for (let part of parts) {
        let key: string | number;
        if (!isNaN(+part) && Number.isInteger(+part)) key = +part
        else key = part
        if (cur[key] === undefined) return undefined
        cur = cur[key]
    }
    return cur
}

export function fitMinSize(cur: any[], minSize: number, empty: Record<string, any>) {
    const toAdd = minSize - cur.length
    if (toAdd <= 0) return cur
    for (let i = 0; i < toAdd; i++)
        cur.push({ ...empty })
    return cur
}

export function handleAutoFieldArray(arr: any[], type: string | undefined, minSize: number, empty: Record<string, any>,
    append: any, remove: any,
    removeCond: (x: any) => boolean,
    appendCond: (x: any) => boolean
) {
    if (arr.length < minSize) append({ ...empty }, { shouldFocus: false })
    else if (type == 'change') {
        const toRemove = []
        for (let i = minSize - 1; i < arr.length - 1; i++)
            if (removeCond(arr[i])) toRemove.push(i) // !arr[i]?.name
        if (appendCond(arr.at(-1))) append({ ...empty }, { shouldFocus: false }) // arr.at(-1)?.name
        if (toRemove.length > 0) remove(toRemove)
    }
}

export function getCronParser(formatCron: number, time: Date | null, daysOfWeek?: (string | number)[], date?: Date | null, rateIntervalDays?: number, daysAddInAdvance: number = 0, duration = 0) {
    if (!time) return null;

    const start = date ? changeTime(date, time).getTime() : undefined
    const cronParser = new EventCronParser(formatCron ? DEFAULT_CRON : DEFAULT_RATE, start);
    cronParser.setDuration(duration)
    if (formatCron && daysOfWeek) {
        cronParser.setUTCHours([time.getUTCHours()], [time.getUTCMinutes()])
        cronParser.setDaysOfWeek(daysOfWeek.map(x => (typeof x === 'string' ? parseInt(x) : x) + 1), 'local')
    } else if (!formatCron && rateIntervalDays) {
        cronParser.setRate(rateIntervalDays, 'days')
    } else {
        console.log("ERROR: neither cron or rate expression")
    }
    try {
        cronParser.validate()
        cronParser.next(Date.now() + daysAddInAdvance * MS_PER_DAY)
    } catch (e) {
        console.error('e', e)
        return null
    }
    return cronParser
}

export const nextAdd = (cron: EventCronParser, offset: number, daysAddInAdvance: number) => {
    if (!cron) return cron
    let date = cron.next(Date.now() + daysAddInAdvance * MS_PER_DAY)
    for (let i = 0; i < offset; i++) {
        date = cron.next()
        if (!date) return null
    }
    if (!date) return null
    return new Date(date.getTime() - daysAddInAdvance * MS_PER_DAY);
}

const itemTypeToFormPathDict = {
    event: 'event.event',
    task: 'task', // 2nd value is any (unread)
    templateRecur: 'event.templateRecur',
} as const
export function getDefaultItemFormValues(itemv: Partial<TodoItemMerge>, parentIdDict: ParentIdDict | undefined, category: string | string[] | undefined) { // not just todo items tho, also templateRecur
    let item: any = { ...itemv }; // TODO: i was lazy again
    if (item.parentId && parentIdDict) {
        item = parentIdDict[item.parentId]
    }
    item = cloneDeep(item)
    const time: [Date | null, Date | null] = [null, null]
    const date: [Date | null, Date | null] = [null, null]
    if (item.dateStart) { time[0] = new Date(item.dateStart); date[0] = new Date(item.dateStart) }
    // else if (item.type === 'templateRecur') { time[0] = new Date(item.dateStart); date[0] = new Date(item.dateStart) }
    if (item.dateEnd) { time[1] = new Date(item.dateEnd); date[1] = new Date(item.dateEnd) }

    const parser = item.cron ? new EventCronParser(item.cron, item.dateStart, item.dateEnd) : null
    const cronInfo = { ...FORM_ITEM_DEFAULTS.cronInfo }
    if (date[0]) {
        cronInfo.daysOfWeek = [date[0].getDay().toString()]
    }

    let durationValue = FORM_ITEM_DEFAULTS.durationValue
    if (parser) {
        cronInfo.formatCron = parser.isRateExpression() ? 0 : 1
        if (cronInfo.formatCron) {
            cronInfo.daysOfWeek = parser.getLocalDays().map(x => (x - 1).toString())
        } else {
            const parsedCron = parser.parsedCron as ParsedRate
            cronInfo.rateIntervalDays = Math.round(parsedCron.rate / secondsInDay)
        }
        time[0] = parser.next()
        durationValue = round(parser.parsedCron.duration / MS_PER_HOUR, 2)
    }

    const routerCategory = isArray(category) ? category.join('/') : undefined
    return cloneDeep({
        ...FORM_ITEM_DEFAULTS,
        ...item,
        ...(item.links && { links: fitMinSize(item.links, 1, EMPTY_LINK) }),
        ...(item.subtasks && { subtasks: fitMinSize(item.subtasks, 2, EMTPY_SUBTASK) }),
        durationValue,
        time,
        date,
        isMultiDay: !!(date[0] && date[1] && !isSameDay(date[0], date[1])),
        // @ts-expect-error TODO: oops lazy again
        ...(item.type && { formPath: itemTypeToFormPathDict[item.type] }),
        cronInfo,
        category: item.category || routerCategory || FORM_ITEM_DEFAULTS.category,
        ...(item.dateHandedOut && { handedOutDate: new Date(item.dateHandedOut) }),
    })
}

export function convertTemplateDBDefaultValues(defaultValues: Partial<TemplateDBMerge> = {}, category?: string | string[] | undefined) {
    const { cron, cronStart, daysAddInAdvance, nextAdd } = defaultValues
    const parser = cron ? new EventCronParser(cron, cronStart) : null

    const cronStartDate = cronStart ? new Date(cronStart) : null
    const routerCategory = isArray(category) ? category.join('/') : undefined

    let res = {
        ...FORM_ITEM_DEFAULTS,
        scheduled: 0,
        cronInfo: {
            formatCron: 1,
            rateIntervalDays: 7,
            daysOfWeek: [(cronStartDate || new Date()).getDay().toString()],
        },
        daysAddInAdvance: 7,
        counter: 1,
        cronNextAddOffset: 0,
        ...defaultValues,
        ...(defaultValues.links && { links: fitMinSize(defaultValues.links, 1, EMPTY_LINK) }),
        ...(defaultValues.subtasks && { subtasks: fitMinSize(defaultValues.subtasks, 2, EMTPY_SUBTASK) }),
        time: [cronStartDate, null],
        date: [cronStartDate, null],
        childType: 'task',
        type: 'templateDB',
        category: defaultValues.category || routerCategory || FORM_ITEM_DEFAULTS.category,
    }
    if (cron && parser) {
        // TODO: calculate cronNextAddOffset
        const nextDay = parser.next(Date.now())
        if (parser.isRateExpression()) {
            const parsedCron = parser.parsedCron as ParsedRate
            res.cronInfo.formatCron = 0
            res.cronInfo.rateIntervalDays = Math.round(parsedCron.rate / secondsInDay)
        } else {
            res.cronInfo.formatCron = 1
            res.cronInfo.daysOfWeek = parser.getLocalDays().map(x => (x - 1).toString())
            res = {
                ...res,
                time: [nextDay, null],
                cronNextAddOffset: 1,
            }
        }
        if (nextAdd && daysAddInAdvance !== undefined) { // TODO: honestly not sure if this works, < seems kinda weak
            let offset = 0
            let nextDate = parser.next(Date.now() + daysAddInAdvance * MS_PER_DAY)?.getTime()
            while (nextDate && nextDate < nextAdd) {
                offset++
                nextDate = parser.next()?.getTime()
            }
            res.cronNextAddOffset = offset
        } // else offset=0
    }
    return res
}